import * as React from "react";
const FcLike = (props) => (
    <svg

        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g
            id="Icon/Heart"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="heart-(4)"
                transform="translate(0.066310, 1.000000)"
                fill="#000000"
                fillRule="nonzero"
            >
                <path
                    d="M31.2140453,5.24515556 C30.093672,2.32678406 27.4043915,0.305062353 24.2896435,0.0395946667 C23.9948453,0.0138808889 23.6969751,0 23.3947813,0 C20.5295304,0.00705388871 17.8079701,1.25557007 15.9336897,3.42277689 C14.0594093,1.25557007 11.3378491,0.00705388871 8.47259818,0 C8.1704044,0 7.87253418,0.0138808889 7.57705329,0.0400497778 C4.4626598,0.305662475 1.77371694,2.32715292 0.653334175,5.24515556 C-0.598419741,8.29960037 -0.0237225419,11.7986086 2.13927195,14.2921956 L15.0747813,29.1905991 C15.2908799,29.4394146 15.6042466,29.5823078 15.9338035,29.5823078 C16.2633604,29.5823078 16.5767271,29.4394146 16.7928257,29.1905991 L29.7281075,14.2921956 C31.891102,11.7986086 32.4657992,8.29960037 31.2140453,5.24515556 Z M28.0107457,12.8 L15.9336897,26.7088782 L3.85697506,12.8 C2.25681204,10.9506584 1.83399678,8.35801626 2.7636844,6.09609956 C3.57222522,3.97645179 5.52042788,2.50482796 7.78037418,2.30661689 C8.00815729,2.28590933 8.23889862,2.27543602 8.47259818,2.27543602 C11.131783,2.26072981 13.5997179,3.65606556 14.9579315,5.942272 C15.1780928,6.26451906 15.5431877,6.45725143 15.9334622,6.45725143 C16.3237367,6.45725143 16.6888315,6.26451906 16.9089928,5.942272 C18.2672887,3.65592491 20.7354344,2.26056795 23.3947813,2.27543602 C23.6281395,2.27543602 23.8592222,2.28613689 24.0858675,2.30661689 C26.3461433,2.50452076 28.2947622,3.97619197 29.1034675,6.09609956 C30.0332165,8.35797428 29.61049,10.9506118 28.0104044,12.8 L28.0107457,12.8 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
export default FcLike;