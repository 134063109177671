import axios from "axios";
import Cookies from "js-cookie";


// const baseUrl = "https://murmurflowers.com/"

export async function postData(url, data) {
    try {
        return await axios.post(`${url}`,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": Cookies.get('csrftoken')
                }
            },
        ).then(response => {
            if (response) {
                return response;
            }
        });
    } catch (err) {
        return err;
    }
}

export async function getData(url) {
    try {
        return await axios.get(`${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": Cookies.get('csrftoken')
                }
            },
        ).then(response => {
            if (response) {
                return response.data;
            }

        });
    }catch (err) {
        return(err);
    }
}

