import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cartLength",
    initialState: {
        cart_length: null,

    },
    reducers: {
        setCartLength: (state, action) => {
            const {cart_length} = action.payload;
            state.cart_length = cart_length;

        },
    },
});
export const { setCartLength } = cartSlice.actions;
export const selectCartLength= (state) => (
    {"cart_length" : state.cartLength.cart_length}
)


export default cartSlice.reducer;
