import React, {useEffect, useState} from 'react';
import "./header.css"
import Logo from "../../assets/images/logo.png"
import VscAccount from '../../assets/svg/VscAccount';
import AiOutlineShoppingCart from '../../assets/svg/AiOutlineShoppingCart';
import FcLike from '../../assets/svg/FcLike';
import {IoCloseOutline} from "react-icons/io5";
import HeaderBurgerMenu from "./headerBurgerMenu";
import HeaderBurgerDropdownCategories from "./headerBurgerDropdownCategories";
import {IoArrowBackOutline} from "react-icons/io5";
import HeaderHoverCategories from "./headerHoverCategories";
import {Link, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectCartLength} from "../../store/authSlice/cartSlice";
import {CiMenuBurger} from "react-icons/ci";
import {RiSearch2Line} from "react-icons/ri";
import {FaTelegram} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa";
import {FaViber} from "react-icons/fa";
import {FaTiktok} from "react-icons/fa";
import {LuSmartphone} from "react-icons/lu";
import {useTranslation} from 'react-i18next';
import '../../language/i18n';
import i18n from '../../language/i18n';
import {getData} from "../../utils/customHooks/customHooks";
import {useNavigate} from 'react-router-dom';
import {selectLikedLength} from "../../store/authSlice/likedProducts";

function Header({
                    changeLanguage,
                    setChangeLanguage,
                    getStateShowSearch,
                    showSearch,
                    showBurgerMenu,
                    getStateShowBurgerMenu,
                    countLikedProducts
                }) {
    const [activeBurgerOption, setActiveBurgerOption] = useState("menu")
    const location = useLocation();
    const cartLength = useSelector(selectCartLength);
    const likedCount = useSelector(selectLikedLength);

    const {t} = useTranslation();
    const lang = JSON.parse(localStorage.getItem("language"));
    const [currentPage, setCurrentPage] = useState(window.location.href.split('/')[4] ? window.location.href.split('/')[4] : 'home');
    const [metaTags, setMetaTags] = useState();
    const navigate = useNavigate();
    if (showBurgerMenu) {
        document.body.style.overflowY = 'hidden';
    } else {
        document.body.style.overflowY = 'visible';
    }
    const getActiveBurgerOption = (options) => {
        setActiveBurgerOption(options);
    }
    const burgerOption = {
        "menu": <HeaderBurgerMenu getStateShowBurgerMenu={getStateShowBurgerMenu}
                                  getStateShowSearch={getStateShowSearch}
                                  getActiveBurgerOption={getActiveBurgerOption}/>,
        "categories": <HeaderBurgerDropdownCategories getStateShowBurgerMenu={getStateShowBurgerMenu} />
    }
    const getLocation = (langLink, changingLang) => {
        if (!window.location.href.includes(langLink)) {
            const mainUrl = window.location.href.includes(changingLang) ? window.location.href.split(changingLang) : window.location.href.split(changingLang)
            mainUrl.splice(1, 0, langLink);
            return mainUrl.join('');
        } else {
            return window.location.href
        }

    }
    const getMetaData = () => {
        const metaObj = {}
        getData('/api/shop/canonical_url/').then(res => {
            setMetaTags(res);
            res.map(tag => {
                if (!metaObj[tag.url_type.name]) {
                    let key = tag.url_type.name
                    metaObj[key] = [tag.url]
                } else {
                    metaObj[tag.url_type.name].push(tag.url)
                }
            })
            setMetaTags(metaObj)
        })
    }
    const removeOldMetaTags = () => {
        const metaTags = document.querySelectorAll('[data-meta-tag]');
        metaTags.forEach(tag => tag.remove());
    };


    useEffect(() => {
        getLocation(lang);
        getMetaData();
        if (window.location.href.includes('//') && window.location.href.split("//").length > 2) {
            navigate('404');
        }
    }, [])

    useEffect(() => {
        removeOldMetaTags();
        if (metaTags && metaTags[currentPage]) {
            metaTags[currentPage].forEach((tag, index) => {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = tag;
                const metaTag = tempDiv.firstChild;
                const tagId = `meta_${currentPage}_${index}`;
                metaTag.setAttribute('id', tagId);
                metaTag.setAttribute('data-meta-tag', 'true'); // Додаємо атрибут для ідентифікації
                if (!document.getElementById(tagId)) {
                    document.head.appendChild(metaTag);
                }
            });
        }
    }, [metaTags, currentPage])

    return (
        <>
            <div className={`header`}>
                <div className="header__top">
                    <div className="header__top__item">
                        <a href="https://www.instagram.com/murmur.flowers.lviv?igsh=ZG13dmtiMGJ0NHJt"
                           className="header__top__item__link">
                            <FaInstagram className="header__top__item__icon"/>
                        </a>
                        <a href="https://t.me/murmurflowers_lviv" className="header__top__item__link">
                            <FaTelegram className="header__top__item__icon"/>
                        </a>
                        <a href="https://m.facebook.com/murmur.flowers.lviv/" className="header__top__item__link">
                            <FaFacebook className="header__top__item__icon"/>
                        </a>
                        <a href="viber://chat?number=%20%2B380670099360" className="header__top__item__link">
                            <FaViber className="header__top__item__icon"/>
                        </a>
                        <div className="header__top__item__btn">
                            <LuSmartphone className="header__top__item__icon"/>
                            <p className="header__top__item__btn__text">
                                +38068175426
                            </p>
                        </div>
                    </div>
                    <div className="header__top__item header__top__item__mobile">
                        <p className="header__top__item__text">
                            {t('contact_time_work')}
                        </p>
                    </div>
                    <div className="header__top__item">
                        <Link className="header__top__item__link" to={`/${lang}/faq`}
                              onClick={() => setCurrentPage('faq')}>FAQ </Link>
                        <Link className="header__top__item__link" to={`/${lang}/contacts`}
                              onClick={() => setCurrentPage('contacts')}> {t('nav_contact')} </Link>
                        <Link className="header__top__item__link" to={`/${lang}/blog`}
                              onClick={() => setCurrentPage('contacts')}> Blog </Link>
                    </div>
                </div>
                <div className="header__container">
                    <div className="header__container__row ">
                        <div className={`header__mobile ${showBurgerMenu ? "active" : ""}`}>
                            <div className="nav__menu__controller__link">
                                {showBurgerMenu &&
                                    (
                                        activeBurgerOption === "menu" ?
                                            <IoCloseOutline
                                                className="nav__menu__controller__icon nav__menu__controller__close-icon "
                                                onClick={() => {
                                                    getStateShowBurgerMenu(!showBurgerMenu)
                                                }}/> :
                                            <IoArrowBackOutline className="nav__menu__controller__icon"
                                                                onClick={() => {
                                                                    setActiveBurgerOption("menu")
                                                                }}
                                            />
                                    )
                                }
                                {!showBurgerMenu &&
                                    <CiMenuBurger
                                        className={`nav__menu__controller__icon ${showBurgerMenu ? "active" : ""}`}
                                        onClick={() => {
                                            getStateShowBurgerMenu(!showBurgerMenu)
                                        }}/>
                                }
                            </div>
                            <div className="header__logo__box-mobile">
                                <Link to={`/${lang}`}
                                      onClick={() => setCurrentPage('home')}>
                                    <img src={Logo} alt="logo" className="header__logo-mobile"/>

                                </Link>
                            </div>
                            <div className="header__mobile__item">
                                {lang === "en" ?
                                    <Link rel="alternate" hreflang="ua" to={getLocation("/ua", '/en')}
                                          className={`nav__menu__controller__change_language__item ${localStorage.getItem("language") === '"ua"' ? "active" : ""}`}
                                          onClick={() => {
                                              localStorage.setItem("language", JSON.stringify("ua"));
                                              i18n.changeLanguage("ua");
                                              setChangeLanguage(!changeLanguage);
                                          }}>
                                        Укр
                                    </Link> :
                                    <Link rel="alternate" hreflang="en" to={getLocation("/en", "/ua")}
                                          className={`nav__menu__controller__change_language__item ${localStorage.getItem("language") === '"en"' ? "active" : ""}`}
                                          onClick={() => {
                                              localStorage.setItem("language", JSON.stringify("en"));
                                              i18n.changeLanguage("en");
                                              setChangeLanguage(!changeLanguage);
                                          }}>
                                        Eng
                                    </Link>
                                }
                                <Link to={`/${lang}/cart`} className="nav__menu__controller__link"
                                      onClick={() => setCurrentPage('cart')}>
                                    <AiOutlineShoppingCart className="nav__menu__controller__icon"/>
                                    {
                                        !showBurgerMenu && cartLength.cart_length !== 0 ?
                                            <span
                                                className="cart__count">{cartLength.cart_length ? cartLength.cart_length : ""} </span> : ""
                                    }

                                </Link>

                            </div>
                        </div>
                        <div className="header__container__row__item" >
                            <nav className="nav">
                                <ul className="nav__menu">
                                    <li className="nav__menu__item nav__menu__item__shop">
                                        <Link to={`/${lang}/shop`}
                                              className={`nav__menu__link  ${location.pathname === '/shop' ? 'active' : ''}`}
                                              onClick={() => setCurrentPage('shop')}
                                        >
                                            {t('nav_shop')}
                                        </Link>
                                        <HeaderHoverCategories/>
                                    </li>
                                    <li className="nav__menu__item">
                                    <Link to={`/${lang}/about-us`} href=""
                                              className={`nav__menu__link ${location.pathname === '/about-us' ? 'active' : ''}`}
                                              onClick={() => setCurrentPage('about-us')}>
                                            {t('nav_about_us')}
                                        </Link>
                                    </li>
                                    <li className="nav__menu__item">
                                        <Link to={`/${lang}/categories`} className={`nav__menu__link`}
                                              onClick={() => setCurrentPage('Categories')}>
                                            {t('shop_category')}
                                        </Link>
                                    </li>
                                    {/*<li className="nav__menu__item">*/}
                                    {/*    <Link to="/contacts" className={`nav__menu__link` } >*/}
                                    {/*        Контакти*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li className="nav__menu__item">
                                        <Link to={`/${lang}/delivery-and-payments-info`} href=""
                                              className={`nav__menu__link ${location.pathname === '/delivery-and-payments-info' ? 'active' : ''}`}
                                              onClick={() => setCurrentPage('delivery-and-payments-info')}>
                                            {t('nav_delivery_info')}
                                        </Link>
                                    </li>
                                    <li className="nav__menu__item">
                                        <Link to={`/${lang}/return-conditions-info`} href=""
                                              className={`nav__menu__link ${location.pathname === '/return-conditions-info' ? 'active' : ''}`}
                                              onClick={() => setCurrentPage('return-conditions-info')}>
                                            {t('nav_return_conditions_info')}
                                        </Link>
                                    </li>
                                    {/*<li className="nav__menu__item">*/}
                                    {/*    <Link to="/faq" href="" className={`nav__menu__link ${location.pathname === '/payments-info' ? 'active' : ''}`}>*/}
                                    {/*        Часті запитання*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav__menu__item">*/}
                                    {/*    <Link to="/blog" href="" className={`nav__menu__link ${location.pathname === '/payments-info' ? 'active' : ''}`}>*/}
                                    {/*        Блог*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                </ul>
                            </nav>
                        </div>
                        <div className="header__container__row__item">
                            <div className="header__logo__box">
                                <Link to={`/${lang}`} className="header__logo__box_link"
                                      onClick={() => setCurrentPage('home')}>
                                    <img src={Logo} alt="" className="header__logo"/>
                                </Link>

                            </div>
                        </div>
                        <div className="header__container__row__item">
                            <div className="nav__menu__right__block">

                                <div className="nav__menu__controller">
                                    <div className="nav__menu__controller__change_language">

                                        <Link rel="alternate" hreflang="ua" to={getLocation("/ua", '/en')}
                                              className={`nav__menu__controller__change_language__item ${localStorage.getItem("language") === '"ua"' ? "active" : ""}`}
                                              onClick={() => {
                                                  localStorage.setItem("language", JSON.stringify("ua"));
                                                  i18n.changeLanguage("ua");
                                                  setChangeLanguage(!changeLanguage);
                                              }}>
                                            Укр
                                        </Link> |
                                        <Link rel="alternate" hreflang="en" to={getLocation("/en", "/ua")}
                                              className={`nav__menu__controller__change_language__item ${localStorage.getItem("language") === '"en"' ? "active" : ""}`}
                                              onClick={() => {
                                                  localStorage.setItem("language", JSON.stringify("en"));
                                                  i18n.changeLanguage("en");
                                                  setChangeLanguage(!changeLanguage);
                                              }}>
                                            Eng
                                        </Link>

                                    </div>
                                    <Link className='nav__menu__controller__link' to={`/${lang}/personal-cabinet`}
                                          onClick={() => setCurrentPage('personal-cabinet')}>
                                        <VscAccount className="nav__menu__controller__icon"/>
                                    </Link>
                                    <RiSearch2Line
                                        className={`nav__menu__controller__icon ${showSearch ? "active" : ""}`}
                                        onClick={() => {
                                            getStateShowSearch(!showSearch)
                                        }}/>
                                    <Link to={`/${lang}/liked`} className="nav__menu__controller__link"
                                          onClick={() => setCurrentPage('liked')}>
                                        <FcLike className="nav__menu__controller__icon"/>
                                        {
                                            likedCount.liked_length && likedCount.liked_length !== 0 ?
                                                <span
                                                    className="cart__count">{likedCount.liked_length} </span> : ''
                                        }
                                    </Link>
                                    <div className="nav__menu__controller__item">
                                        <Link to={`/${lang}/cart`} className="nav__menu__controller__link"
                                              onClick={() => setCurrentPage('cart')}>
                                            <AiOutlineShoppingCart className="nav__menu__controller__icon"/>
                                            {
                                                !showBurgerMenu && cartLength.cart_length && cartLength.cart_length !== 0 ?
                                                    <span
                                                        className="cart__count">{cartLength.cart_length ? cartLength.cart_length : ""} </span> : ""
                                            }

                                        </Link>
                                    </div>
                                    {/*<div className="nav__menu__controller__item">*/}
                                    {/*    <p className="nav__menu__controller__item__city" onClick={()=>{*/}
                                    {/*        setShowChooseYourCityModal(true);*/}
                                    {/*    }}>*/}
                                    {/*        {!localStorage.getItem("city")?"Львів":JSON.parse(localStorage.getItem("city"))} <IoIosArrowDown />*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showBurgerMenu && burgerOption[activeBurgerOption]}
            </div>
        </>
    );

}

export default Header;