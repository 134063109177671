import React, {useEffect, useState} from 'react';
import "./search.css"
import {BsArrowLeft}  from "react-icons/bs"
import { motion } from 'framer-motion';
import {getData} from "../../utils/customHooks/customHooks";
import SearchResultProductsItem from "./searchResultProductsItem";
import { RiSearch2Line } from "react-icons/ri";
import {useTranslation} from "react-i18next";

function Search({getStateShowSearch}) {
    const [searchData, setSearchData] = useState(null);
    const [showMessage , setShowMessage]= useState(false);
    const {t} = useTranslation();

    const handleSearchInputData = (e) =>{
        if (e.target.value.length>0){
            getSearchResult(e.target.value);
        }else{
            setSearchData(null)
        }

    }
    const getSearchResult = (searchInputValue)=>{
        getData(`/api/products/search_product_name_or_category/?search=${searchInputValue}`).then(res=>{
            if(res.code === "ERR_BAD_REQUEST"){
                setSearchData(null);
                setShowMessage(true);
            }else{
                setSearchData(res);
                setShowMessage(false);

            }
        })
    }

    useEffect(() => {
    }, [searchData]);
    return (
        <>
            <motion.div className="search"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.3 }}
            >
                <div className="search__container">
                    <div className="search__input__box">
                        <RiSearch2Line className="search__input__box__icon search__input__box__search__icon"/>
                        <input placeholder={t("search_input_placeholder")} type="text" className="search__input" onChange={handleSearchInputData}/>
                        <BsArrowLeft className="search__input__box__icon search__input__box__search__btn__icon" onClick={getSearchResult}/>
                    </div>
                    <div className="search__close__btn" onClick={()=>{getStateShowSearch(false)}}>
                        {t("search_close")}
                    </div>
                    {
                        searchData?
                        <div className="search__result__row">
                            {
                                searchData.products.map(product=>
                                    <SearchResultProductsItem product={product} getStateShowSearch={getStateShowSearch} />
                                )
                            }
                        </div>:""
                    }
                    {showMessage&&
                        <div className="search__result__row">
                          <div className="search__result__row__message">
                              {t("search_message")}
                          </div>
                        </div>
                    }
                </div>

            </motion.div>

        </>
    );
}

export default Search;