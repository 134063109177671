import { createSlice } from "@reduxjs/toolkit";

const scrollSlice = createSlice({
    name: "scroll",
    initialState: {
        scroll__id: null,

    },
    reducers: {
        setScroll: (state, action) => {
            const {scroll__id  } = action.payload;
            state.scroll__id = scroll__id;

        },
    },
});
export const { setScroll } = scrollSlice.actions;
export const selectScroll = (state) => (
    {"scroll__id" : state.scroll.scroll__id}
)


export default scrollSlice.reducer;
