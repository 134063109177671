import * as React from "react";
const VscAccount = (props) => (
    <svg

        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g
            id="Icon/User"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="user-(7)"
                transform="translate(5.000000, 1.000000)"
                fill="#000000"
                fillRule="nonzero"
            >
                <path
                    d="M11.1111111,11.1111111 C8.0428625,11.1111111 5.55555556,8.62380417 5.55555556,5.55555556 C5.55555556,2.48730695 8.0428625,0 11.1111111,0 C14.1793597,0 16.6666667,2.48730695 16.6666667,5.55555556 C16.6630543,8.62230662 14.1778622,11.1074987 11.1111111,11.1111111 Z M11.1111111,2.22222222 C9.27016195,2.22222222 7.77777778,3.71460639 7.77777778,5.55555556 C7.77777778,7.39650472 9.27016195,8.88888889 11.1111111,8.88888889 C12.9520603,8.88888889 14.4444444,7.39650472 14.4444444,5.55555556 C14.4424237,3.71544408 12.9512226,2.22424301 11.1111111,2.22222222 L11.1111111,2.22222222 Z"
                    id="Shape"
                />
                <path
                    d="M11.1111111,30 C8.80486495,29.9835472 6.50592621,29.7385723 4.248,29.2686667 C3.85624225,29.1833089 3.5408577,28.8935083 3.42274074,28.5103524 C3.30462378,28.1271965 3.40209738,27.7101226 3.67779761,27.4190049 C3.95349783,27.1278873 4.36465648,27.0078859 4.75366667,27.105 C6.84617066,27.5336844 8.97522947,27.7589925 11.1111111,27.7777778 C13.7511111,27.7766667 18.2834444,27.3274444 20,26.1474444 L20,24.4444444 C20,19.5352467 16.0203089,15.5555556 11.1111111,15.5555556 C6.20191333,15.5555556 2.22222222,19.5352467 2.22222222,24.4444444 L2.22222222,26.6666667 C2.22222222,27.2803164 1.72476083,27.7777778 1.11111111,27.7777778 C0.497461389,27.7777778 0,27.2803164 0,26.6666667 L0,24.4444444 C0,18.3079472 4.97461389,13.3333333 11.1111111,13.3333333 C17.2476083,13.3333333 22.2222222,18.3079472 22.2222222,24.4444444 L22.2222222,26.6666667 C22.2222222,26.9613276 22.1050549,27.243897 21.8966667,27.4522222 C19.4205556,29.9283333 11.9542222,30 11.1111111,30 L11.1111111,30 Z"
                    id="Path"
                />
            </g>
        </g>
    </svg>
);
export default VscAccount;