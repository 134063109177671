
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationUA from "./locales/ua/translation.json"
import translationEN from "./locales/en/translation.json"
i18n
    .use(initReactI18next)
    .init({
        resources: {
            ua: {translation: translationUA},
            en: {translation: translationEN},
        },
        lng: "ua",
        fallbackLng: "ua",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
