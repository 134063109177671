import { createSlice } from "@reduxjs/toolkit";

const subCategoriesSlice = createSlice({
    name: "category",
    initialState: {
        sub_category_name: null,

    },
    reducers: {
        setSubCategories: (state, action) => {
            const {sub_category_name  } = action.payload;
            state.sub_category_name = sub_category_name;

        },
    },
});
export const { setSubCategories } = subCategoriesSlice.actions;
export const selectSubCategories= (state) => (
    {"sub_category" : state.sub_category_name}
)


export default subCategoriesSlice.reducer;
