import { createSlice } from "@reduxjs/toolkit";

const categoriesSlice = createSlice({
    name: "category",
    initialState: {
        category_name: null,

    },
    reducers: {
        setCategories: (state, action) => {
            const {category_name  } = action.payload;
            state.category_name = category_name;

        },
    },
});
export const { setCategories } = categoriesSlice.actions;
export const selectCategories= (state) => (
    {"category_name" : state.category.category_name}
)


export default categoriesSlice.reducer;
