import React, {useEffect, useState} from 'react';
import {getData} from "../../utils/customHooks/customHooks";
import {Link} from "react-router-dom";
import {setCartLength} from "../../store/authSlice/cartSlice";
import {setCategories} from "../../store/authSlice/categoriesSlice";
import {useDispatch} from "react-redux";

function HeaderHoverCategories() {
    const [allCategories, setAllCategories] = useState(null)
    const lang = JSON.parse(localStorage.getItem("language"));
    const dispatch = useDispatch();

    const getAllCategories = () => {
        getData("/api/categories/").then(res => {
            setAllCategories(res)
        })
    }
    useEffect(() => {
        getAllCategories();
    },[])
    return (
        <div>
            <div className="header__hover__categories">
                <div className="header__hover__categories__container">
                    <div className="header__hover__categories-side header__hover__categories__left-side">
                        {allCategories && allCategories.map(category =>
                            <Link to={`${lang}/shop/category_slug=${category.slug}`}
                                  className="header__hover__categories-side-item active" onClick={()=>{
                                dispatch((setCategories({
                                    "category_name": lang === "en" ? category.name_eng : category.name
                                })))
                            }
                            }>
                                {lang === "en" ? category.name_eng : category.name}
                            </Link>

                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderHoverCategories;