import React, {useState} from 'react';
import "./storeFront.css";
import {HiOutlineShoppingBag} from "react-icons/hi2";
import FcLike from "../../assets/svg/FcLike";
import {Link} from "react-router-dom";
import {getData, postData} from "../../utils/customHooks/customHooks";
import {useDispatch} from "react-redux";
import {setCartLength} from "../../store/authSlice/cartSlice";
import {useTranslation} from 'react-i18next';

import Star from "../../assets/svg/Star";
import {setLikedLength} from "../../store/authSlice/likedProducts";

function StoreFrontCard({liked, product, setShowContinueShopingModal, setCountLikedProducts}) {
    const dispatch = useDispatch();
    const [currentIncrease, setCurrentIncrease] = useState(1);
    const [flowerPrice, setFlowerPrice] = useState(product.general_discounts.length !== 0 ? product.price_with_discount : product.price);
    const [flowerPriceWithoutDiscount, setFlowerPriceWithoutDiscount] = useState(product.price);
    const [currentImage, setCurrentImage] = useState("image_1");
    const lang = JSON.parse(localStorage.getItem("language"));
    const {t} = useTranslation();


    const addProductToLiked = (e) => {
        postData(`/api/like/add_like_to_product/`, {
            product: product.id,
        }).then(res => {
            if (res.status === 200) {
                getData("/api/like/get_like_to_product").then(res => {
                    if (res !== []) {
                        dispatch((setLikedLength({
                            "liked_length": res.length
                        })))
                    }
                })
                if (e.target.className.baseVal.includes("liked")) {
                    e.target.classList.remove('liked');
                } else {
                    e.target.classList.add('liked');
                }
            }
        })
    }

    const addToCart = (id) => {
        postData("/api/basket/add_to_basket/", {
            product: id,
            size: currentIncrease,
            quantity: 1
        })
        getData("/api/basket/get_data_from_basket/").then(res => {
            dispatch((setCartLength({
                "cart_length": res.len_basket
            })))
        })
    }
    const increasePercentage = {
        "1": "0",
        "2": "100",
        "3": "200",
        "4": "300",
    }

    const addMaximumIncreaseValue = () => {
        const currentFlowerPrice = product.general_discounts.length !== 0 ? product.price_with_discount : product.price;
        const elements = [];
        for (let i = 2; i <= product.maximum_increase; i++) {
            elements.push(
                <div key={i}
                     className={`storefront__card__increase__change__item ${currentIncrease === i ? "active" : ""}`}
                     onClick={() => {
                         if (currentIncrease === i) {
                             setCurrentIncrease(1);
                             setCurrentImage(`image_${1}`)
                             setFlowerPrice(currentFlowerPrice);
                             if (product.general_discounts.length !== 0) {
                                 setFlowerPriceWithoutDiscount(product.price)
                             }
                         } else {
                             setCurrentIncrease(i);
                             setCurrentImage(`image_${i}`)
                             setFlowerPrice(currentFlowerPrice * i);
                             if (product.general_discounts.length !== 0) {
                                 setFlowerPriceWithoutDiscount(product.price * i)
                             }
                         }
                     }}>
                    +{increasePercentage[i]}%
                </div>
            );
        }
        return elements;
    };
    const getGradientPercentage = (star_id, rating) => {
        if (rating - star_id >= 0) {
            return 100;
        } else if ((rating % 1 * 100 && rating - star_id > -1) > 0) {
            return rating % 1 * 100;
        } else {
            return 0;
        }
    }
    return (
        <>
            {product &&

                <div className="storefront__card" id={product.id}>
                    <Link to={`/${lang}/flower-page/${product.id}/${product.slug}`} style={{width: '100%'}}>
                        <div className="storefront__card__img-box">
                            <img

                                src={currentImage === "image_1" ? product.photo.main_photo :
                                    (product && product.photo_for_increase && product.photo_for_increase.currentImage !== null ? product.photo_for_increase[currentImage] : product.photo.main_photo)}
                                alt={lang === "en" ? product.name_eng : product.name}
                                className="storefront__card__img"/>
                            <FcLike
                                className={`storefront__card__option__mobile__icon heart-icon ${product.was_liked || product.like ? "liked" : ""}`}
                                onClick={(e) => {
                                    addProductToLiked(e);
                                }}
                            />

                            <Link to={`/${lang}/flower-page/${product.id}/${product.slug}`}
                                  className="storefront__card__detail">
                                {t("storefront_detail")}
                            </Link>
                        </div>
                    </Link>
                    {/*<div className="storefront__card__option">*/}
                    {/*    <FcLike*/}
                    {/*        className={`storefront__card__option__mobile__icon ${product.was_liked || product.like ? "liked" : ""}`}*/}
                    {/*        onClick={(e) => {*/}
                    {/*            addProductToLiked(e);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <HiOutlineShoppingBag className="storefront__card__option__mobile__icon" onClick={() => {*/}
                    {/*        addToCart(product.id);*/}
                    {/*        setShowContinueShopingModal(true)*/}
                    {/*    }}/>*/}
                    {/*</div>.heart-icon*/}
                    <div style={{display: 'flex', justifyContent: "space-between", padding: '5px 15px 0px 15px'}}>
                        <Link to={`/${lang}/flower-page/${product.id}/${product.slug}`}>
                            <div className="storefront__card__price-box">
                                {product.general_discounts.length !== 0 ?
                                    <div className="storefront__card__discount_price">
                                        {product.price_with_discount && flowerPrice} {t("persona_cabinet_user_order_currency")}
                                    </div> : ''
                                }
                                <div className="storefront__card__current_price">
                                    {product.general_discounts.length !== 0 ? flowerPriceWithoutDiscount : flowerPrice} {t("persona_cabinet_user_order_currency")}
                                </div>
                            </div>
                            {product.general_discounts.length !== 0 ?
                                <div className="storefront__card__discount">
                                    <p className="storefront__card__discount__percentage">
                                        {product.general_discounts[0].discount}%
                                    </p>
                                </div> : ""
                            }
                        </Link>
                        <HiOutlineShoppingBag className="storefront__card__option__mobile__icon" onClick={() => {
                            addToCart(product.id);
                            setShowContinueShopingModal(true)
                        }}/>
                    </div>
                    <div style={{padding: '0px 15px 5px 15px'}}>
                        {product.name}
                    </div>
                </div>

            }

        </>
    );
}

export default StoreFrontCard;