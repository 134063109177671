import React, {useEffect, useState} from 'react';
import './quickDial.css';
import { AiOutlinePhone } from "react-icons/ai";
import Logo from "../../assets/images/logo.png"
import { IoMdSend } from "react-icons/io";
import {IoCloseOutline} from "react-icons/io5";
import {postData} from "../../utils/customHooks/customHooks";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {phoneValidate} from "../../utils/phoneValidate";

function QuickDial() {
    const [showSlideForm , setShowSlideForm] = useState(false);
    const {
        register, getValues,trigger, setValue, handleSubmit,
        formState: {errors}
    } = useForm({mode: 'onSubmit'});
    const {t} = useTranslation();

    const validateForm = async (e) => {
        e.preventDefault();
        try {
            await trigger();
            if (getValues('user_phone')) {
                sendPhone()
            } else {
            }
        } catch (error) {
            return false;
        }
    };
    const sendPhone = () => {
        const data = {
            phone_number: getValues("user_phone"),
            type: "question",
        }
        if(getValues('user_phone').length === 12){
            postData('/api/quick_order/', data).then(res => {
                if (res.status === 200) {
                    console.log('success');
                    setShowSlideForm(false)
                } else {
                    console.error('Sending failed:', res.error);
                }
            });
        }
    }
    useEffect(()=>{
        setValue("user_phone","380")
    },[])
    return (
        <>
            <div className="quick__dial">
                <div className="quick__dial__container">

                        <div className={`quick__dial__slide__form ${showSlideForm?"show":''}`}>
                            <IoCloseOutline className="quick__dial__contact__form__close__icon" onClick={()=>{
                                setShowSlideForm(false)
                            }}/>
                            <div className="quick__dial__contact__form">
                                <p className="quick__dial__contact__form__text">
                                    {t("quick_dial")}
                                </p>
                                <div className="quick__dial__contact__form__input__box">
                                    <input required  type="number" className="quick__dial__contact__form__input"
                                            {...register("user_phone",{
                                                required: t("auth_required"),
                                                pattern: {
                                                    value: /^.{12}$/,
                                                    message: t("phone_length")
                                                }
                                                })}
                                            onChange={(e)=>{
                                                phoneValidate(e);
                                            }}/>
                                    <div className="quick__dial__contact__form__submit-btn" onClick={validateForm}>
                                        <IoMdSend className="quick__dial__contact__form__submit-btn__icon" />
                                    </div>
                                </div>
                                {errors.user_phone && <div
                                    className="auth-page__form_error">{errors.user_phone?.message}</div>}
                            </div>
                        </div>
                    <AiOutlinePhone className={`quick__dial__icon quick__dial__icon__desktop ${showSlideForm?"hide":''}`} onClick={()=>{
                        setShowSlideForm(!showSlideForm)
                    }}/>
                    <a href="tel:+6494461709" className="quick__dial__icon__mobile__box">
                        <AiOutlinePhone className="quick__dial__icon quick__dial__icon__mobile" />
                    </a>

                </div>

            </div>

        </>
    );
}

export default QuickDial;