import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {BsArrowLeft} from "react-icons/bs"
import './hero.css';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
import {getData} from "../../utils/customHooks/customHooks";
import {Link} from "react-router-dom";
import {setScroll} from "../../store/authSlice/scrollSlice";
import {setCategories} from "../../store/authSlice/categoriesSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


function Hero() {
    const lang = JSON.parse(localStorage.getItem("language"));
    const swiperRef = useRef(null);
    const nextButton = useRef(null);
    const prevButton = useRef(null);
    const [banners, setBanners] = useState(null);
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const {t} = useTranslation();

    useEffect(()=>{
        getData("/api/all_banners/").then(res => {
            setBanners(res);
        })
    },[])

    useEffect(() => {
        const swiper = swiperRef.current?.swiper;
        if (swiper && banners && banners.length > 1) {
            nextButton.current.addEventListener('click', () => {
                swiper.slideNext(200);
            });
            prevButton.current.addEventListener('click', () => {
                swiper.slidePrev(200);
            });
        }
    }, [banners]);

    return (
        <>
            {banners && <div className="hero">

                <Swiper
                    ref={swiperRef}
                    loop={true}
                    speed={1000}
                    autoplay={{delay: 3000}}
                    parallax={true}
                    modules={[Autoplay]}
                    className="mySwiper hero__swiper"
                >
                    {banners && banners.map(banner => <SwiperSlide className="hero__swiper__slide "
                                                                   style={{background: `url(${width < 600 ? banner.img_for_phone : banner.img}) no-repeat center`}}>
                        <div className="hero__swiper__slide__content">

                            <h2 className="hero__swiper__slide__content__sub-title">
                                {lang === "en" ? banner.text_eng : banner.text}
                            </h2>
                            <Link to={`/${lang}/shop/category_slug=${banner.category.slug}/`}
                                  className="hero__swiper__slide__content__btn-link main-btn-link hero__swiper__slide__content__btn main-btn">
                                {t("hero_buy_flower")}
                            </Link>

                        </div>

                    </SwiperSlide>)
                    }

                </Swiper>
                {banners && banners.length > 1 ?
                    <div className="hero__swiper__custom__btn-box">
                        <div ref={prevButton} className="hero__swiper__custom__btn custom-prev">
                            <BsArrowLeft/>
                        </div>
                        <div ref={nextButton} className="hero__swiper__custom__btn custom-next"><BsArrowLeft/></div>
                    </div> : ""
                }


            </div>}
        </>
    );
}

export default Hero;