import * as React from "react";
const AiOutlineShoppingCart = (props) => (
    <svg

        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g
            id="Icon/Bag"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="bag-(1)"
                transform="translate(4.000000, 1.000000)"
                fill="#000000"
                fillRule="nonzero"
            >
                <path
                    d="M11.9375,16.82 C9.07598267,16.8234227 6.61026343,14.8056355 6.0475,12 C6.00347199,11.7099277 6.08905141,11.4151544 6.28157074,11.1937572 C6.47409008,10.9723599 6.75412415,10.8466777 7.0475,10.85 C7.53967974,10.8428441 7.96387262,11.1949242 8.0475,11.68 C8.43567549,13.5327847 10.0694888,14.8597714 11.9625,14.8597714 C13.8555112,14.8597714 15.4893245,13.5327847 15.8775,11.68 C15.9611274,11.1949242 16.3853203,10.8428441 16.8775,10.85 C17.1708759,10.8466777 17.4509099,10.9723599 17.6434293,11.1937572 C17.8359486,11.4151544 17.921528,11.7099277 17.8775,12 C17.3111489,14.8241882 14.8177848,16.847423 11.9375,16.82 L11.9375,16.82 Z"
                    id="Path"
                />
                <path
                    d="M20.8375,30.0000024 L3.0375,30.0000024 C2.21516623,30.0010286 1.42846092,29.6644514 0.861333743,29.0689679 C0.294206563,28.4734843 -0.00361736386,27.6713058 0.0375,26.85 L0.8475,9.61 C0.917152708,8.00412328 2.24011415,6.73849016 3.8475,6.73999865 L20.0275,6.73999865 C21.6348858,6.73849016 22.9578473,8.00412328 23.0275,9.61 L23.8375,26.85 C23.8786174,27.6713058 23.5807934,28.4734843 23.0136663,29.0689679 C22.4465391,29.6644514 21.6598338,30.0010286 20.8375,30.0000024 L20.8375,30.0000024 Z M3.8475,8.75 C3.29521525,8.75 2.8475,9.19771525 2.8475,9.75 L2.0375,26.95 C2.02379421,27.2237686 2.12306885,27.4911614 2.31211125,27.689656 C2.50115364,27.8881505 2.76338874,28.0003429 3.0375,28.0000008 L20.8375,28.0000008 C21.1116113,28.0003429 21.3738464,27.8881505 21.5628888,27.689656 C21.7519311,27.4911614 21.8512058,27.2237686 21.8375,26.95 L21.0275,9.71 C21.0275,9.15771525 20.5797847,8.71 20.0275,8.71 L3.8475,8.75 Z"
                    id="Shape"
                />
                <path
                    d="M17.9375,7.75 L15.9375,7.75 L15.9375,6 C15.9375,3.790861 14.146639,2 11.9375,2 C9.728361,2 7.9375,3.790861 7.9375,6 L7.9375,7.75 L5.9375,7.75 L5.9375,6 C5.9375,2.6862915 8.6237915,-8.8817842e-16 11.9375,-8.8817842e-16 C15.2512085,-8.8817842e-16 17.9375,2.6862915 17.9375,6 L17.9375,7.75 Z"
                    id="Path"
                />
            </g>
        </g>
    </svg>
);
export default AiOutlineShoppingCart;