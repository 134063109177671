import React from 'react';
import Star from "../../assets/svg/Star";

function RewiewsRatingStar({comment,index}) {


    const getCurrentRating = ()=>{
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <Star
                    key={index+i}
                    className={`reviews__swiper__slide__rating__icon_${index}`}
                    id={i}
                    start_offset={(comment.rating-i)*100}
                />
            );
        }
        return stars;
    }

    return (
        <div className="reviews__swiper__slide__rating">
            {getCurrentRating()}
        </div>
    );
}

export default RewiewsRatingStar;