import React, {useEffect, useState} from 'react';
import "./why-us.css"
import '../../language/i18n';

import {useTranslation} from "react-i18next";
import {getData} from "../../utils/customHooks/customHooks";

function WhyUs() {
    const [activeAdvantageId, setActiveAdvantageId] = useState(0)
    const {t} = useTranslation();
    const [advantages, setAdvantages] = useState([]);
    const lang = JSON.parse(localStorage.getItem("language"));

    const getAllAdvantages = () => {
        getData('/api/main_description').then(res => {
            setAdvantages(res)
        })
    }

    useEffect(() => {
        getAllAdvantages()
    }, []);
    return (
        <>
            {advantages && advantages.length !== 0 &&
                <div className="why-us">
                    <div className="why-us__container container">
                        <h2 className="why-us__title main__title">
                            {t('main_why_us')}
                        </h2>
                        <div className="why-us__row">
                            <div className="why-us__title__row">
                                {advantages && advantages.map((advantage, index) => (

                                    <div style={{width: `${100 / advantages.length}%`}}
                                         className={`why-us__title__row__item ${index === activeAdvantageId ? "active" : ""}`}
                                         key={index}
                                         onClick={() => {
                                             setActiveAdvantageId(index)
                                         }}>
                                        {lang === "ua" ? advantage.title : advantage.title_eng}
                                    </div>
                                ))}

                            </div>
                            <div className="why-us__row__content__row">
                                {
                                    advantages.map((advantage, index) => (
                                        <div
                                            className={`why-us__row__content__row__item ${index !== activeAdvantageId ? "hide" : ""}`}>
                                            <p className="why-us__row__content__row__item__text">
                                                {lang === "ua" ? advantage.text : advantage.text_eng}
                                            </p>
                                        </div>
                                    ))
                                }


                            </div>
                        </div>


                    </div>
                </div>
            }

        </>
    );
}

export default WhyUs;