import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./authSlice/registrationSlice";
import scrollReducer from "./authSlice/scrollSlice";
import categoriesSlice from "./authSlice/categoriesSlice";
import cartSlice from "./authSlice/cartSlice";
import subCategoriesSlice from "./authSlice/subCategoriesSlice";
import likedSlice from './authSlice/likedProducts'

export const store = configureStore({
    reducer: {
        registration: authReducer,
        scroll:scrollReducer,
        category:categoriesSlice,
        cartLength:cartSlice,
        sub_category_name:subCategoriesSlice,
        likedLength: likedSlice
    },
    devTools: true
})