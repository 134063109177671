import React from 'react';
import './search.css';
import {FaRegEye} from "react-icons/fa";
import {Link} from "react-router-dom";

function SearchResultProductsItem({product,getStateShowSearch}) {
    const lang = JSON.parse(localStorage.getItem("language"));

    return (
        <>
            <Link to= {`/${lang}/flower-page/${product.id}/${product.slug}`} className="search__result__row__item_link" onClick={()=>{
                getStateShowSearch(false)
            }}>
                <div className="search__result__row__item">
                    <div className="search__result__row__item__img__box">
                        <img src={product.photo.main_photo} alt="" className="search__result__row__item__img"/>
                    </div>
                    <div className="search__result__row__item__name">
                        {lang==="en"?product.name_eng:product.name}
                    </div>
                    <div className="search__result__row__item__price">
                        {product.price}
                    </div>

                    <FaRegEye className="search__result__row__item_icon"/>
                </div>
            </Link>


        </>
    );
}

export default SearchResultProductsItem;