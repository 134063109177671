import React, {useEffect, useState} from 'react';
import './seoBlock.css'
import {getData} from "../../utils/customHooks/customHooks";

function SeoBlock({apiUrl}) {
    const [seoBlockData, setSeoBlockData] = useState(null);
    const lang = JSON.parse(localStorage.getItem("language"));

    const getSeoBlockData = () => {
            getData(apiUrl).then(res => {
                setSeoBlockData(res);
            })
    }

    useEffect(() => {
        getSeoBlockData();
    }, [apiUrl]);

    return (
        <>
            {seoBlockData && seoBlockData.block_seo_data &&  seoBlockData.block_seo_data.length > 0 &&
                <div className="seoBlock">
                    <div className="seoBlock__container container">
                        <div className="seoBlock__row">
                            {seoBlockData.block_seo_data[0].img &&
                                <div className="seoBlock__img__box">
                                    <img
                                        src={seoBlockData.block_seo_data[0].img}
                                        alt="" className="seoBlock__img"/>
                                </div>
                            }
                            <div className="seoBlock__content">
                                <h3 className="seoBlock__title">
                                    {lang === "en" ? seoBlockData.block_seo_data[0].title_eng :
                                        seoBlockData.block_seo_data[0].title }
                                </h3>
                                <p className="seoBlock__text"
                                   dangerouslySetInnerHTML={{
                                       __html: lang === "en" ? seoBlockData.block_seo_data[0].text_eng :
                                           seoBlockData.block_seo_data[0].text
                                   }}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default SeoBlock;