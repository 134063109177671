import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {getData} from "../../utils/customHooks/customHooks";
import {setCategories} from "../../store/authSlice/categoriesSlice";
import {Link} from "react-router-dom";
import { useDispatch } from "react-redux";

function HeaderBurgerDropdownCategories({getStateShowBurgerMenu}) {
    const [allCategories, setAllCategories] = useState(null)
    const lang = JSON.parse(localStorage.getItem("language"));
    const dispatch = useDispatch();

    const getAllCategories = () => {
        getData("/api/categories/").then(res => {
            setAllCategories(res)
        })
    }
    useEffect(()=>{
        getAllCategories();
    })
    return (
        <div>
            <motion.div
                initial={{opacity: 1, x: -50}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: -50}}
                transition={{duration: 0.3}}
                className="header__burger">
                <div className="header__burger__menu__container">
                    <ul className="header__burger__menu">
                        {allCategories&&allCategories.map(category=>
                            <li className="header__burger__menu__item" >
                                <Link to={`${lang}/shop/category_slug=${category.slug}__category_name=${category.name}`} className="header__burger__menu__link"
                                      onClick={()=>{
                                   getStateShowBurgerMenu(false)
                                }}>
                                    {category.name}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </motion.div>
        </div>
    );
}

export default HeaderBurgerDropdownCategories;