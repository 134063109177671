import React, {useEffect, useState} from 'react';
import "./reviewsPage.css";
import {MdOutlineReviews} from "react-icons/md";
import RewiewsRatingStar from "../reviews/rewiewsRatingStar";
import {IoIosArrowDown} from "react-icons/io";


function ReviewsPageComments({comment, index}) {
    const [commentsLength, setCommentsLength] = useState([]);
    const [commentText , setCommentText] = useState("");
    const maxCommentLength = 200;
    const [showReadMoreButton, setShowReadMoreButton] = useState(true);
    function commentsLengthValidate(text) {
        if(text.length >= 250){
            setCommentText(text.slice(0, maxCommentLength)+"...");
        }else{
            setCommentText(text)
        }
    }
    const showAllComments = (comment) => {
        setCommentText(comment.text)
        setShowReadMoreButton(false)
    }
    useEffect(() => {
        commentsLengthValidate(comment.text)
        setCommentsLength(comment.text.length)
    }, [comment])

    return (
        <>
            {comment &&
                <div className="reviews__row__masonry__card">
                    <div className="reviews__swiper__slide__content">
                        <MdOutlineReviews className="reviews__swiper__slide__content__icon"/>
                        <p className="reviews__author-name">
                            {comment.username}
                        </p>
                        <RewiewsRatingStar comment={comment} index={index}/>
                        <p
                            className="reviews__row__masonry__card-comment"
                            dangerouslySetInnerHTML={{__html: commentText}}
                        />
                        {commentsLength >= 250 && showReadMoreButton &&
                            <div className="reviews__row__masonry__card__read__more" onClick={() => {
                                showAllComments(comment);
                            }}>
                                Читати більше
                                <IoIosArrowDown
                                    className={'reviews__row__masonry__card__read__more__icon'}
                                />
                            </div>
                        }
                    </div>
                </div>
            }

        </>

    )
        ;
}

export default ReviewsPageComments;