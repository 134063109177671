import React, { useState, useEffect } from 'react';
import "./blog.css";
import { useParams } from "react-router-dom";
import {getData, postData} from "../../utils/customHooks/customHooks";
import {
    LuCalendar,
    LuEye,
    LuHourglass,
    LuInstagram,
    LuUser,
    LuArrowDownCircle
} from "react-icons/lu";
import admin_a from "../../assets/images/admin_a.png";
import flowerImage from "../../assets/images/img_3678.jpg";
import {Link} from "react-router-dom";
import FlowersLikeTo from "./flowers_like_to";

function Blog() {
    const lang = JSON.parse(localStorage.getItem("language"));
    const [productsTop5, setProductsTop5] = useState([])
    const [article, setArticle] = useState();
    const [qaquest, setqa] = useState();
    const { slug } = useParams();
    const [visibleFaq, setVisibleFaq] = useState(null);
    const [comments, setComments] = useState(['']);
    const [commentEmail, setCommentEmail] = useState('');
    const [commentName, setCommentName] = useState('');
    const [commentMessage, setCommentMessage] = useState('');
    const [top_3_articles, setTop_3_articles] = useState([])
    const [blogCategoryList, setBlogCategoryList] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleFaq = (index) => {
        setVisibleFaq(visibleFaq === index ? null : index);
    };
    const placeComment = () => {
        postData("/api/blog/comment/", {
            "slug": slug,
            "email": commentEmail,
            "name": commentName,
            "content": commentMessage,
        })
            .then(res => {
                setComments(res.data);
            }
        );
        setCommentEmail("")
        setCommentName("")
        setCommentMessage("")
    }
    useEffect(() => {
        postData("/api/blog/article/", { slug })
            .then(res => {
                setArticle(res.data.article);
                setqa(res.data.faq);
                setComments(res.data.comments);
                setProductsTop5(res.data.featured_product);
            });
    }, [slug]);
    useEffect(() => {
        getData("/api/blog/categories/").then(res => {
            setBlogCategoryList(res)
        })
    }, [])
    useEffect(() => {
        getData("/api/blog/articles/").then(res => {
            setTop_3_articles(res.top_five_articles.slice(0, 3))
        })
    }, [])
    if (!article) {
        return <div>Loading...</div>;
    }

    return (
        <div className="blog">
            <div className="blog__container container" style={{gap: 10}}>
                <div>
                    <a href={'/'+lang+'/'} style={{color: 'black'}}>Головна</a> > <a href={'/'+lang+'/blog'} style={{color: 'black'}}>Блог</a> > <a href={`/${lang}/blog/category/${article.category?.slug}`} style={{color: 'black'}}>{article.category?.title}</a> > {article.name}
                </div>
                <div style={{marginBottom: 250, marginTop: 15}}>
                    <div className="addEmailContainer" style={{
                        height: 280,
                        backgroundImage: `url(${article.img})`,
                        backgroundColor: 'none',
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        justifyContent: "space-between",
                    }}>
                        <div></div>
                        <div style={{fontSize: 48, color: 'white'}}>{article.name}</div>
                        <div style={{
                            display: "flex",
                            gap: 10,
                            width: '100%',
                            justifyContent: 'right',
                            marginLeft: "-100px",
                            padding: 10,
                        }}>
                            <LuInstagram style={{opacity: 0, marginLeft: '-40px'}}/>
                            <svg className='socialsvg'
                                 style={{backgroundColor: '#ffffff70', padding: 5, borderRadius: 10}}
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                            </svg>
                            <svg className='socialsvg'
                                 style={{backgroundColor: '#ffffff70', padding: 5, borderRadius: 10}}
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 496 512">
                                <path
                                    d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"/>
                            </svg>
                            <svg className='socialsvg'
                                 style={{backgroundColor: '#ffffff70', padding: 5, borderRadius: 10}}
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512">
                                <path
                                    d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
                            </svg>
                            <svg className='socialsvg'
                                 style={{backgroundColor: '#ffffff70', padding: 5, borderRadius: 10}}
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512">
                                <path
                                    d="M444 49.9C431.3 38.2 379.9 .9 265.3 .4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9 .4-85.7 .4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9 .4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9 .6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4 .7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5 .9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9 .1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7 .5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1 .8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "360px", display: "flex", gap: 10}}>
                    <div
                        style={{display: "flex", alignItems: 'center', gap: 5}}>
                        <LuEye style={{color: "#EC5E5A"}}/>
                        {article.views}
                    </div>
                    <div
                        style={{display: "flex", alignItems: 'center', gap: 5}}>
                        <LuCalendar style={{color: "#EC5E5A"}}/>
                        {article.created_at}
                    </div>
                    <div
                        style={{display: "flex", alignItems: 'center', gap: 5}}>
                        <LuHourglass style={{color: "#EC5E5A"}}/>
                        {article.reading_time}
                    </div>
                    <div
                        style={{display: "flex", alignItems: 'center', gap: 5}}>
                        <LuHourglass style={{color: "#EC5E5A"}}/>
                    </div>
                </div>
                <div className='Artcontent' style={{...(windowWidth < 760 && { flexDirection: "column"}) }}>
                    <div dangerouslySetInnerHTML={{__html: article.content}} className='content' style={{...(windowWidth < 760 && { width: "100%"})}} />
                    <div style={{display: "flex", flexDirection: 'column', gap: 10, width:'100%',...(windowWidth < 760 ? { width: "100%"} : {width: '25%'})}}>
                        <div className='catTitle'>
                            НАШІ РУБРИКИ
                        </div>
                        <div style={{display:'flex', gap:10, flexWrap: 'wrap'}}>
                            {blogCategoryList.map((cat, index) => (
                                <Link to={`/${lang}/blog/category/${cat.slug}`}>
                                    <div style={{backgroundColor: '#EC5E5A99', padding: '10px 20px', borderRadius: 20, color:'black'}}>{cat.title}</div>
                                </Link>
                            ))}
                        </div>
                        <div className='catTitle'>
                            ПОПУЛЯРНІ СТАТТІ
                        </div>
                        <div style={{display: 'flex', flexDirection: "column", gap: 20}}>
                            {top_3_articles.map((article, index) => (
                                <Link to={`/${lang}/blog/${article.slug}`}>
                                    <div style={{display: 'flex', flexDirection:"column",gap:10,color:'black'}}>
                                        <div style={{display: 'flex', gap: 20}}>
                                            <img src={article.img} alt="" style={{width:'50%'}}/>
                                            <div style={{display: 'flex', flexDirection: 'column', gap: 20, width: '50%'}}>
                                                <div style={{fontSize: 22}}>{article.name}</div>
                                                <div
                                                    style={{display: "flex", alignItems: 'center', gap: 5}}>
                                                    <LuEye style={{color: "#EC5E5A"}}/>
                                                    {article.views}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{fontSize:18}}>{article.description.length > 50
                                            ? article.description.substring(0, 50) + '...'
                                            : article.description}</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>


                <div
                    style={{display: 'flex', alignItems: 'center', flexDirection: "column", gap: 10, marginBottom: 50}}>
                    <div style={{
                        backgroundColor: "#F49E9C",
                        padding: '20px',

                        fontSize: "24px",
                        border: 'solid 1px',
                        borderRadius: '15px',
                        textAlign: 'center',
                        ...(windowWidth < 760 ? { width: "auto"}:{width: '600px',})
                    }}>Часто поставлені запитання
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: "column", gap: 10, ...(windowWidth < 760 && { width: "100%"})}}>
                        {qaquest.map((faq, index) => (
                            <div style={{width:'100%'}}>
                                <div className="qaarticle" key={index} style={{...(windowWidth < 760 ? { width: "auto"}:{width: '600px',})}}>
                                    <p style={{fontSize: 25, cursor: 'pointer', display: 'flex', justifyContent:'space-between', alignItems:'center'}} onClick={() => toggleFaq(index)}>
                                        {faq.title}
                                        <LuArrowDownCircle/>
                                    </p>
                                    <p style={{display: visibleFaq === index ? 'block' : 'none'}}>
                                        {faq.text}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{display: 'flex', gap:10, marginBottom: 50, flexWrap: 'wrap'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 15, backgroundColor:'#D9D9D9', padding:'5px 15px', borderRadius: 10, width: 'max-content'}}>
                        <svg className='socialsvg'
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512">
                            <path
                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                        </svg>
                        Поділитись
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 15,
                        backgroundColor: '#D9D9D9',
                        padding: '5px 15px',
                        borderRadius: 10,
                        width: 'max-content'
                    }}>
                        <svg className='socialsvg'
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 496 512">
                            <path
                                d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"/>
                        </svg>
                        Поділитись
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 15,
                        backgroundColor: '#D9D9D9',
                        padding: '5px 15px',
                        borderRadius: 10,
                        width: 'max-content'
                    }}>
                        <svg className='socialsvg'
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path
                                d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
                        </svg>
                        Поділитись
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 15,
                        backgroundColor: '#D9D9D9',
                        padding: '5px 15px',
                        borderRadius: 10,
                        width: 'max-content'
                    }}>
                        <svg className='socialsvg'
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path
                                d="M444 49.9C431.3 38.2 379.9 .9 265.3 .4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9 .4-85.7 .4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9 .4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9 .6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4 .7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5 .9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9 .1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7 .5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1 .8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"/>
                        </svg>
                        Поділитись
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center',
                    ...(windowWidth < 760 ? { width: "75%"}:{width: '25%',}),
                    border: 'solid 1px',
                    padding: 10,
                    borderRadius: 15
                }}>
                    <img src={admin_a} alt="logo" style={{width: 50}}/>
                    <div style={{width: '100%'}}>
                        <div style={{fontSize: 20}}>Автор
                            Статті: {article.article_author.first_name} {article.article_author.last_name}</div>
                        <div>квітковий експерт</div>
                    </div>
                </div>

                {comments.length !== 0 && (
                    <div style={{marginTop: 10, display: "flex", flexDirection: "column", gap: 10}}>
                        <div style={{fontSize: 24}}>Коментарі</div>
                        <div style={{display: 'flex', gap: 30, flexWrap: 'wrap', width: "100%"}}>
                            {comments.map((comment, index) => (
                                <div style={{display: 'flex', gap: 10, alignItems: 'center', ...(windowWidth < 760 ? { width: "100%"}:{width: '25%',}),}}>
                                    <LuUser style={{fontSize: 30}}></LuUser>
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <span style={{fontSize: 20}}>{comment.name}</span>
                                            <span style={{color: '#7B7B7B'}}>{comment.created_at}</span>
                                        </div>
                                        <div>{comment.content}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div style={{marginTop: 20, display: "flex", flexDirection: "column", gap: 15, alignItems: 'center'}}>
                    <div style={{fontSize: 30, width: 'max-content'}}>Додати коментар</div>
                    <div className={'leaveComment'} style={{...(windowWidth < 760 ? { width: "81%"}:{width: '400px',})}}>
                        <input type="text" placeholder={"Коментар"} value={commentMessage}
                               onChange={event => setCommentMessage(event.target.value)}/>
                        <div style={{display: 'flex', gap: 15, width: '100%'}}>
                            <input style={{width: '50%'}} type="text" placeholder={"Ваше ім’я"} value={commentName}
                                   onChange={event => setCommentName(event.target.value)}/>
                            <input style={{width: '50%'}} type="text" placeholder={"Ваш e-mail"} value={commentEmail}
                                   onChange={event => setCommentEmail(event.target.value)}/>
                        </div>
                        <div className={"btn"} onClick={placeComment}>Відправити коментар</div>
                    </div>
                </div>
                <FlowersLikeTo flowersList={productsTop5}></FlowersLikeTo>
            </div>
        </div>
    );
}

export default Blog;
