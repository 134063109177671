import './main.css';
import React, {lazy, Suspense, useEffect, useState} from "react";
import i18n from './language/i18n';
import {Helmet} from "react-helmet";
import 'swiper/css';
import 'swiper/css/navigation';
import {Route, BrowserRouter, Routes, Navigate, useParams} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Search from "./components/search/search";
import Header from "./components/header/header";
import Homepage from "./components/homepage/homepage";
import Footer from "./components/footer/footer";
import QuickDial from "./components/quickDial/quickDial";
import NotFoundPage from "./components/404/notFoundPage";
import Bottom__menu from "./components/bottomMenu/bottom__menu";
import {getData} from "./utils/customHooks/customHooks";
import TagManager from 'react-gtm-module';
import ContinueShopingModal from "./components/continueShopingModal/continueShopingModal";
import ReviewsPage from "./components/reviewsPage/reviewsPage";
import { useNavigate } from 'react-router-dom';
import Blog from "./components/blog/blog";
import BlogArticle from "./components/blog/blogArticle";
import BlogCategory from "./components/blog/blogCategory";


function App() {

    const [showSearch, setShowSearch] = useState(false);
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    // const [showChooseYourCityModal , setShowChooseYourCityModal] = useState(!localStorage.getItem("city"))
    const [changeLanguage, setChangeLanguage] = useState(false);
    const [block1, setBlok1] = useState(null);
    const [block2, setBlok2] = useState(null);
    const [analyticsCode, setAnalyticsCode] = useState(null);
    const AuthPageRegistration = lazy(() => import('./components/authPage/authPageRegistration'));
    const AuthPageLogin = lazy(() => import('./components/authPage/authPageLogin'));
    const PersonalCabinet = lazy(() => import('./components/personalCabinet/personalCabinet'));
    const Shop = lazy(() => import('./components/shop/shop'));
    const ProductPage = lazy(() => import('./components/productPage/productPage'));
    const AboutUs = lazy(() => import('./components/aboutUs/aboutUs'));
    const ShoppingCart = lazy(() => import('./components/shopingCart/shoppingCart'));
    const PaymentAndDeliveryMainInfo = lazy(() => import('./components/paymentAndDeliveryMainInfo/paymentAndDeliveryMainInfo'));
    const LikedProducts = lazy(() => import('./components/likedProducts/likedProducts'));
    const OrderPaymentsForm = lazy(() => import('./components/orderPaymentsForm/orderPaymentsForm'));
    const Contacts = lazy(() => import('./components/contacts/contacts'));
    const Faq = lazy(() => import('./components/faq/faq'));
    const CategoriesPage = lazy(() => import('./components/categoriesPage/categoriesPage'));
    const BlogAuthorPage = lazy(() => import('./components/blog/blogAuthor'));
    const [showContinueShopingModal , setShowContinueShopingModal] = useState(false);
    const [countLikedProducts ,setCountLikedProducts ] = useState(0)
    const lang = JSON.parse(localStorage.getItem("language"));


    const LazyCategoriesPage = () => (
        <Suspense>
            <CategoriesPage/>
        </Suspense>
    );
    const LazyAuthPageRegistration = () => (
        <Suspense>
            <AuthPageRegistration/>
        </Suspense>
    );
    const LazyAuthPageLogin = () => (
        <Suspense>
            <AuthPageLogin/>
        </Suspense>
    );
    const LazyPersonalCabinet = () => (
        <Suspense>
            <PersonalCabinet/>
        </Suspense>
    );
    const LazyShop = () => (
        <Suspense>
            <Shop setShowContinueShopingModal={setShowContinueShopingModal}
                  showContinueShopingModal={showContinueShopingModal} setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyProductPage = () => (
        <Suspense>
            <ProductPage showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}
                         setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyAboutUs = () => (
        <Suspense>
            <AboutUs/>
        </Suspense>
    );
    const LazyShoppingCart = () => (
        <Suspense>
            <ShoppingCart/>
        </Suspense>
    );
    const LazyPaymentAndDeliveryMainInfo = ({type}) => (

        <Suspense>
            <PaymentAndDeliveryMainInfo type={type}/>
        </Suspense>
    );
    const LazyLikedProducts = () => (
        <Suspense>
            <LikedProducts showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal}
                           setCountLikedProducts={setCountLikedProducts}/>
        </Suspense>
    );
    const LazyOrderPaymentsForm = () => (
        <Suspense>
            <OrderPaymentsForm/>
        </Suspense>
    );
    const LazyContacts = () => (
        <Suspense>
            <Contacts/>
        </Suspense>
    );
    const LazyFaq = () => (
        <Suspense>
            <Faq/>
        </Suspense>
    );
    const BlogArticleDetails = () => (
        <Suspense>
            <BlogArticle/>
        </Suspense>
    );
    const BlogCategoryDetails = () => (
        <Suspense>
            <BlogCategory/>
        </Suspense>
    );
    const BlogAuthor = () => (
        <Suspense>
            <BlogAuthorPage/>
        </Suspense>
    );
    const LazyReviews = () => (
        <Suspense>
            <ReviewsPage/>
        </Suspense>
    );
    const getStateShowSearch = (state) => {
        setShowSearch(state)
    }
    const getStateShowBurgerMenu = (state) => {
        setShowBurgerMenu(state)
    }


    useEffect(() => {
        // if(!isLang){
        //     window.history.replaceState(null, '', '/');
        // }
        if(window.location.href.split("/")[3]==="en" || window.location.href.split("/")[3]==="ua"){
            localStorage.setItem("language", JSON.stringify(window.location.href.split("/")[3]));
            i18n.changeLanguage(window.location.href.split("/")[3])
        }else if (!localStorage.getItem("language")) {
            localStorage.setItem("language", JSON.stringify("ua"));
        } else {
            i18n.changeLanguage(JSON.parse(localStorage.getItem("language")))
        }
        getData('/api/get_product_with_block1/').then(res => {
            setBlok1(res);
        })
        getData('/api/get_product_with_block2/').then(res => {
            setBlok2(res);
        })
        getData("/api/shop/analytics_code/").then(res => {
            setAnalyticsCode(res);
            TagManager.initialize({
                gtmId: res.code_google_analytics
            });
        })
        getData("/api/like/get_like_to_product").then(res => {
            setCountLikedProducts(res.length)
        })
    }, [])

    return (
        <BrowserRouter>
            <div className="App">
                <Helmet>
                    <link rel="alternate" hreflang="en" href="https://murmurflowers.com/en"/>
                    <link rel="alternate" hreflang="ua" href="https://murmurflowers.com/"/>
                </Helmet>

                <QuickDial/>
                <AnimatePresence>
                    {showSearch && <Search getStateShowSearch={getStateShowSearch}/>}
                </AnimatePresence>
                <Header countLikedProducts={countLikedProducts} changeLanguage={changeLanguage} setChangeLanguage={setChangeLanguage}
                        getStateShowSearch={getStateShowSearch} showSearch={showSearch} showBurgerMenu={showBurgerMenu}
                        getStateShowBurgerMenu={getStateShowBurgerMenu}
                />
                <main className="main">
                    <Routes>
                        <Route path="/" element={<Navigate
                            to={`${lang?lang==='ua'?'/ua':'/en':"/ua"}`} />} />
                        <Route path="/:language" element={<Homepage setCountLikedProducts={setCountLikedProducts} showContinueShopingModal={showContinueShopingModal} setShowContinueShopingModal={setShowContinueShopingModal} block1={block1} block2={block2}/>}/>
                        <Route path="/:language/personal-cabinet" element={<LazyPersonalCabinet/>}/>
                        <Route path="/:language/registration" element={<LazyAuthPageRegistration/>}/>
                        <Route path="/:language/login" element={<LazyAuthPageLogin/>}/>
                        <Route path="/:language/shop/:params?/:page?" element={<LazyShop/>}/>
                        <Route path="/:language/flower-page/:id/:slug"   element={<LazyProductPage />}/>
                        <Route path="/:language/about-us" element={<LazyAboutUs/>}/>
                        <Route path="/:language/liked" element={<LazyLikedProducts/>}/>
                        <Route path="/:language/payments" element={<LazyOrderPaymentsForm/>}/>
                        <Route path="/:language/cart" element={<LazyShoppingCart/>}/>
                        <Route path="/:language/delivery-and-payments-info" element={<LazyPaymentAndDeliveryMainInfo type={"delivery-and-payments"}/>}/>
                        <Route path="/:language/return-conditions-info" element={<LazyPaymentAndDeliveryMainInfo type={"return-conditions"}/>}/>
                        <Route path="/:language/contacts" element={<LazyContacts/>}/>
                        <Route path="/:language/faq" element={<LazyFaq/>}/>
                        <Route path="/:language/categories" element={<LazyCategoriesPage/>}/>
                        <Route path="/:language/blog" element={<Blog/>}/>
                        <Route path="/:language/blog/:slug" element={<BlogArticleDetails/>}/>
                        <Route path="/:language/blog/author/:slug" element={<BlogAuthor/>}/>
                        <Route path="/:language/blog/category/:slug" element={<BlogCategoryDetails/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                        <Route path="/404" element={<NotFoundPage/>}/>
                        <Route path="/:language/reviews" element={<LazyReviews/>}/>
                        {/*<Route path="/blog" element={<Blog/>}/>*/}
                        {/*<Route path="/blog/1" element={<BlogPages/>}/>*/}
                    </Routes>
                </main>
                {block1 && block2 && <Footer block1={block1} block2={block2}/>}
                <Bottom__menu getStateShowSearch={getStateShowSearch} showSearch={showSearch}/>
            </div>
        </BrowserRouter>
    );
}

export default App;
