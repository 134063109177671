import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice({
    name: "registration",
    initialState: {
        user_name: null,
        user_password:null,
        isAuthenticated: false,
    },
    reducers: {
        setUser: (state, action) => {
            const {user_name , user_password } = action.payload;
            state.user_name = user_name;
            state.user_password = user_password;
            state.isAuthenticated = true;
        },
    },
});
export const { setUser } = registrationSlice.actions;
export const selectIsAuthenticated = (state) => state.registration.isAuthenticated;
export const selectUserAuthInfo = (state) => (
    {"user_name" : state.registration.user_name, "user_password":state.registration.user_password,}
)


export default registrationSlice.reducer;
