import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {BsArrowLeft} from "react-icons/bs"
import {MdKeyboardArrowRight, MdOutlineReviews} from "react-icons/md";
import "./reviews.css";
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import {useTranslation} from "react-i18next";
import '../../language/i18n';
import {getData} from "../../utils/customHooks/customHooks";
import RewiewsRatingStar from "./rewiewsRatingStar";
import {Link} from "react-router-dom";

function Reviews() {
    const {t} = useTranslation();
    const reviewsSwiperRef = useRef(null);
    const reviewsNextButton = useRef(null);
    const reviewsPrevButton = useRef(null);
    const [comments, setComments] = useState([]);
    const lang = JSON.parse(localStorage.getItem("language"));

    const getAllComments = () => {
        getData('/api/main_reviwed/').then(res => {
            setComments(res)
        })
    }
    useEffect(()=>{
        getAllComments();
    },[])
    useEffect(() => {

        const swiper = reviewsSwiperRef.current?.swiper;
        if (swiper) {
            reviewsNextButton.current.addEventListener('click', () => {
                swiper.slideNext(1000);
            });

            reviewsPrevButton.current.addEventListener('click', () => {
                swiper.slidePrev(1000);
            });
        }
    }, [comments]);
    return (
        <>
            {comments && comments.length!==0 &&
                <div className="reviews" id="reviews">
                    <div className="reviews__container container">
                        <div className="reviews__header">
                            <h2 className="reviews__title main__title container"> {t('main_reviews')}</h2>
                            <div className="reviews__header__btn ">
                                <Link to= {`/${lang}/reviews`} className="storefront__btn__link ">
                                    {t("all_reviews")}
                                </Link>
                                <MdKeyboardArrowRight />
                            </div>
                        </div>


                        <div className="reviews__swiper__container ">
                            <Swiper
                                ref={reviewsSwiperRef}
                                loop={true}
                                speed={1000}
                                pagination={true}
                                modules={[Pagination]}
                                slidesPerView={3}
                                spaceBetween={20}
                                className="mySwiper reviews__swiper container"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },

                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1000: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                {comments.map((comment, index) => (
                                    <SwiperSlide className="reviews__swiper__slide" key={index}>
                                        <div className="reviews__swiper__slide__content">
                                            <MdOutlineReviews className="reviews__swiper__slide__content__icon"/>
                                            <p className="reviews__author-name">
                                                {comment.username}
                                            </p>
                                            <RewiewsRatingStar comment={comment} index={index}/>
                                            <p className="reviews__text-comment">
                                                {comment.text}
                                            </p>

                                        </div>
                                    </SwiperSlide>
                                ))
                                }

                            </Swiper>

                            <div className="reviews__swiper__custom__btn-box container">
                                <div ref={reviewsPrevButton} className="reviews__swiper__custom__btn custom-prev">
                                    <BsArrowLeft/>
                                </div>
                                <div ref={reviewsNextButton} className="reviews__swiper__custom__btn custom-next">
                                    <BsArrowLeft/></div>
                            </div>
                        </div>

                    </div>
                </div>
            }


        </>
    );
}

export default Reviews;