import React, {useEffect} from 'react';
import {LuHeart, LuShoppingBag, LuShoppingCart} from "react-icons/lu";

const FlowersLikeTo = ({flowersList, isAddtProduct=false}) => {
    useEffect(() => {
        console.log(flowersList)
    }, [flowersList])
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 30, width: '100%', margin: '40px 0'}}>
            {!isAddtProduct &&
                <div style={{fontSize: 35,}}>Вам може сподобатись</div>
            }
            <div style={{display: 'flex', gap: '15px', width: '100%'}}>
                {flowersList.map((flower, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '19%', gap: 10 }}>
                        <div style={{ width: '100%', position: 'relative', aspectRatio: '1 / 1' }}>
                            {isAddtProduct
                                ? <img
                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                    src={flower.photo}
                                    alt={flower.name}
                                />
                                : <img
                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                    src={flower.photo.main_photo}
                                    alt={flower.name}
                                />}

                            <div className="heart-icon">
                                <LuHeart/>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: 20 }}>{flower.price} грн</div>
                            <LuShoppingBag style={{ fontSize: 20 }} />
                        </div>
                        <div style={{ fontSize: 14 }}>{flower.name}</div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default FlowersLikeTo;