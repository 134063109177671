import React, {useEffect, useState} from 'react';
import './reviewsPage.css';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getData} from "../../utils/customHooks/customHooks";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ReviewsPageComments from "./reviewsPageComments";
import {MdKeyboardArrowRight} from "react-icons/md";

function ReviewsPage() {
    const {t} = useTranslation();
    const lang = JSON.parse(localStorage.getItem("language"));
    const [comments, setComments] = useState([]);

    const getAllComments = () => {
        getData('/api/main_reviwed/').then(res => {
            setComments(res)
        })
    }

    useEffect(() => {
        getAllComments();
    }, [])

    return (
        <>
            <section className="reviews__page">
                <div className="reviews__page__container container">
                    <div className="page__navigation container">
                        <Link to={`/${lang}`} className="page__navigation__link">
                            {t("nav_home")}
                        </Link>
                        <MdKeyboardArrowRight/>
                        <Link
                            to={`/${lang}/reviews`}
                            className="page__navigation__link">
                            {t('product_page_review')}
                        </Link>
                    </div>
                    <h1 className="reviews__page__title">
                        {t('product_page_review')}
                    </h1>
                    {
                        comments &&
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{300: 1, 560: 2, 1400: 3}}
                        >
                            <Masonry
                                columnsCount={3} gutter="30px"
                                className="reviews__row__masonry"
                            >
                                {
                                    comments.map((comment, index) => (
                                        <ReviewsPageComments comment={comment} index={index}/>
                                    ))
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                    }
                </div>
            </section>
        </>
    );
}

export default ReviewsPage;