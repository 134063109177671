import { createSlice } from "@reduxjs/toolkit";

const likedSlice = createSlice({
    name: "likedLength",
    initialState: {
        liked_length: null,

    },
    reducers: {
        setLikedLength: (state, action) => {
            const {liked_length} = action.payload;
            state.liked_length = liked_length;

        },
    },
});
export const { setLikedLength } = likedSlice.actions;
export const selectLikedLength= (state) => (
    {"liked_length" : state.likedLength.liked_length}
)


export default likedSlice.reducer;
